import { phoneReg } from '@/utils/regUtil'

export const columns = [
	{
		prop: 'userNo',
		label: '用户编号',
	},
	{
		prop: 'realName',
		label: '用户姓名',
	},
	{
		prop: 'mobile',
		label: '手机号',
	},
	{
		prop: 'companyList',
		label: '所属公司',
		width: 300,
		formatter: function (row, column, cellValue, index) {
			if (!cellValue || cellValue.length === 0) {
				return '无'
			}
			return cellValue.map(item => item.companyName).join(',')
		},
	},
	{
		prop: 'roleList',
		label: '所属角色',
		width: 300,
		formatter: function (row, column, cellValue, index) {
			if (!cellValue || cellValue.length === 0) {
				return '无'
			}
			return cellValue.map(item => item.roleName).join(',')
		},
	},
	{
		prop: 'status',
		label: '状态',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 10 ? '已启用' : '已禁用'
		},
		width: 'auto',
	},
]

export const filters = {
	expand: true,
	filters: [
		{
			label: '用户姓名',
			attr: 'realName',
			type: 'search',
			placeholder: '请输入用户姓名',
			clearable: true,
		},
		{
			label: '手机号',
			attr: 'mobile',
			type: 'search',
			placeholder: '请输入用户手机号',
			clearable: true,
		},
		{
			attr: 'roleId',
			label: '所属角色',
			type: 'select',
			placeholder: '选择所属角色',
		},
		{
			attr: 'status',
			label: '用户状态',
			type: 'select',
			placeholder: '请选择用户状态',
			options: [
				{ name: '已启用', code: 10 },
				{ name: '已禁用', code: 20 },
			],
		},
		{
			attr: 'companyIdList',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '选择所属公司',
			width: 250,
		},
	],
}

export const rules = {
	mobile: [
		{ required: true, message: '请输入登录账号', trigger: 'blur' },
		{ pattern: phoneReg, message: '请输入正确的手机号格式', trigger: 'blur' },
	],
	realName: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
	status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
	companyIdList: [{ required: true, message: '请选择合作公司', trigger: 'blur' }],
	password: [{ min: 6, max: 15, message: '密码最少6位, 最多15位', trigger: 'blur' }],
}
