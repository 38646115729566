<template>
	<el-dialog
		v-model="isShowDialog"
		width="650px"
		class="role-dialog-container"
		:title="dialogTitle"
		:before-close="close"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="账号编号" prop="userNo">
							<el-input
								v-model="formData.userNo"
								placeholder="请输入账号编号"
								maxlength="20"
								show-word-limit
								clearable
								disabled
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="账号姓名" prop="realName">
							<el-input
								v-model="formData.realName"
								placeholder="请输入账号姓名"
								maxlength="20"
								show-word-limit
								clearable
								disabled
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="用户角色" prop="roleIdList">
							<el-select
								v-model="formData.roleIdList"
								filterable
								multiple
								placeholder="请选择用户角色"
								style="width: 100%"
							>
								<el-option
									v-for="(item, index) in roleOptions"
									:key="item.id"
									:label="item.roleName"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button :loading="isLoading" type="primary" :disabled="isDisabled" @click="onSubmit">
					保 存
				</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, inject, reactive, getCurrentInstance, ref, defineEmits } from 'vue'
import { rules } from '../config'
import md5 from 'js-md5'

const formInstance = getCurrentInstance()
const $message = inject('$message')
const $messageBox = inject('$messageBox')
const emit = defineEmits(['refresh'])

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const dialogTitle = ref('')

const formInitValue = {
	roleIdList: [],
	userId: '',
}

const formData = reactive({ ...formInitValue })
const formRule = rules
const companyOptions = ref([])
const roleOptions = ref([])
const statusOptions = [
	{ name: '已启用', code: 10 },
	{ name: '已禁用', code: 20 },
]

const formType = ref('create')
let waitUpdateId = ''

function initEnums() {
	Request({
		url: 'suf4-user-service/role/list',
		method: 'POST',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('枚举获取成功 ==>', res.data)
				roleOptions.value = res.data
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function create() {
	formType.value = 'create'
	dialogTitle.value = '设置角色'
	initEnums()
	isShowDialog.value = true
}

function update(item) {
	formType.value = 'update'
	dialogTitle.value = '设置角色'
	waitUpdateId = item.id
	Object.assign(formData, item)
	if (item.roleList) formData.roleIdList = item.roleList.map(item => item.id)
	initEnums()
	isShowDialog.value = true
}

function deleteItem() {
	$messageBox
		.confirm('确定将删除该条数据？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {})
		.catch(() => {})
}

async function onSubmit() {
	try {
		isLoading.value = true
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) {
			throw new Error('表单校验失败, 请检查!')
		}
		console.log('表单校验成功 ==>', formValidRes, formData)
		let requestUrl = ''
		const params = {}
		if (formType.value === 'create') {
			params.password = md5(params.password)
			delete params.id
			requestUrl = `suf4-user-service/userInfo/create`
		} else {
			requestUrl = `suf4-user-service/userInfo/submitRole`
			params.userId = waitUpdateId
			params.roleIdList = formData.roleIdList
		}
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: params,
		})
		if (updateRes.code === 200) {
			$message.success(`${formType.value === 'create' ? '创建' : '设置'}成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	isShowDialog.value = false
	Object.assign(formData, formInitValue)
}

defineExpose({ create, update, deleteItem })
</script>

<style lang="less" scoped>
.role-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
		.checkbox-box {
			width: 100%;
			margin-top: 10px;
			overflow: hidden;
			.checkbox-item {
				width: 100%;
				overflow: hidden;
				.item-children {
					width: 100%;
					overflow: hidden;
					.no-children-two {
						display: inline-block;
					}
					.children-one {
						margin-left: 25px;
					}
					.children-two {
						margin-left: 50px;
					}
				}
			}
		}
	}
}
</style>
